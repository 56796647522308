@keyframes showUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  min-height: 100vh;
  background: rgba($color: #000000, $alpha: 0.75);
  display: flex;
  padding: 2rem;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    animation: showUp 1 250ms linear;
    max-width: 400px;
    background: $section-2-color;
    padding: 2.5rem 2rem 2rem;
    border-radius: 0.5rem;
    border: 2px solid $section-color;
    position: relative;
  }

  &__close-button {
    background: none;
    border: none;
    display: block;
    font-size: 2rem;
    margin-left: auto;
    color: $danger-color;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
}
