.home {
  @mixin section($max: 12rem) {
    padding: 6rem 2rem;
    @media screen and (min-width: 768px) {
      padding: $max 2rem;
    }
  }

  &__hero {
    @include section(15rem);
    height: calc(100vh - 6rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 2rem;

    &--title {
      font-size: 5rem;
      color: $text-color;
      text-shadow: 3px 3px rgba($alert-color, 1);
    }

    &--description {
      font-size: 1.8rem;
      line-height: 2.6rem;
      max-width: 400px;
    }

    &--links {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;

      a {
        padding: 1rem 3rem;
        width: 130px;
        border: 2px solid $alert-color;
        border-radius: 0.5rem;
        font-size: 1.8rem;
        display: inline-block;
        color: $alert-color;
        font-weight: bold;
        font-family: $title-font;

        &:hover {
          background: $section-3-color;
          color: $text-color;
        }
      }

      @media screen and(min-width: 425px) {
        flex-direction: row;
        gap: 2rem;
      }
    }
    @media screen and (min-width: 768px) {
      height: auto;
    }
  }

  &__about {
    @include section();
    background: $section-3-color;
    display: grid;
    gap: 4rem;
    text-align: center;

    &--article {
      width: 100%;
      margin: 0 auto;
      max-width: 350px;

      h3 {
        font-size: 2.6rem;
        color: $text-color;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.6rem;
        line-height: 2.6rem;
      }
    }
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__tutorial {
    @include section();
    background: $section-color;
  }
}
