@keyframes showUpMobile {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showUpDesktop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.page {
  min-height: calc(100vh - 6rem);
  animation: showUpMobile 2s linear;
  @media screen and (min-width: 768px) {
    animation: showUpDesktop 2s linear;
  }
}
