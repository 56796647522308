@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Font Families;
$title-font: "Ubuntu", sans-serif;
$text-font: "Montserrat", sans-serif;

// Color;
$background-color: #18191a;
$section-color: #242526;
$section-2-color: #1c1e21;
$section-3-color: #593d88;
$text-color: #f5f6f7;
$alert-color: #ba8fff;
$danger-color: #911;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  font-family: $text-font;
  background-color: $background-color;
  color: $text-color;
  width: 100%;
  min-width: 320px;

  &::-webkit-scrollbar {
    background: $background-color;
    width: 1rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($text-color, 0.8);
    border-radius: 0.5rem;
  }

  &.Menu {
    height: 100vh;
    overflow: hidden;

    @media screen and (min-width: 768px) {
      height: auto;
      overflow: initial;
    }
  }
}

p,
button,
a {
  text-decoration: none;
  color: inherit;
  font-size: 1.4rem;
  color: inherit;
  font-family: inherit;
}

button {
  background: $section-3-color;
  border: 2px solid $section-3-color;
  font-weight: bold;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font;
  color: $alert-color;
}

h1 {
  font-size: 2.8rem;
}
h2 {
  font-size: 2.4rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.6rem;
}
