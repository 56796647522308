.Input {
  width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    display: block;
    width: 100%;
  }

  input {
    display: block;
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background: $text-color;
    color: $background-color;
    font-size: 1.4rem;
    padding: 1rem 1rem;
    border: none;
    border-radius: 0.5rem;
    box-shadow: inset 0 0 5px #000000;
    outline: none;
  }
}
