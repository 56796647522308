.Reset-password-modal {
  width: 100%;
  display: grid;
  gap: 1rem;

  h2 {
    text-align: center;
  }

  button {
    background: $section-3-color;
    border: none;
    height: 4rem;
    border-radius: 0.5rem;
  }
}
