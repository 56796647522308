.Footer {
  background: $section-2-color;
  padding: 4rem 2rem;
  display: grid;
  gap: 2rem;

  &__slogan {
    text-align: center;
    h2 {
      margin-bottom: 1rem;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    hr {
      max-width: 320px;
      width: 100%;
      margin: 0 auto;
      border-color: $alert-color;
      @media screen and (min-width: 600px) {
        width: initial;
        margin: 0;
      }
    }
    &--social-media {
      width: 100%;
      max-width: 280px;
      margin: 0 auto;
      ul {
        display: flex;
        padding: 1rem 0;
        gap: 2rem;
        justify-content: center;
        li {
          a {
            font-size: 2rem;
            display: flex;
            padding: 1rem;
            background: $section-color;
            border-radius: 50%;

            &:active {
              background: $section-3-color;
            }
            &:hover {
              background: $section-3-color;
            }
          }
        }
      }
      @media screen and (min-width: 600px) {
        max-width: 200px;
        margin: 0;
        ul {
          justify-content: flex-start;
        }
      }
    }
    &--more-links {
      width: 100%;
      max-width: 280px;
      margin: 0 auto;
      @media screen and (min-width: 600px) {
        max-width: 200px;
        margin: 0;
      }
    }
    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  &__copyright {
    text-align: center;
  }
}
