.Not-found {
  display: grid;
  align-content: center;
  padding: 4rem 2rem;
  text-align: center;
  gap: 2rem;
  h2 {
    font-size: 3rem;
    color: $text-color;
    text-shadow: 4px 4px rgba($section-color, 1);
  }
  a {
    padding: 1rem 3rem;
    margin: 0 auto;
    border: 2px solid $alert-color;
    border-radius: 0.5rem;
    font-size: 1.8rem;
    color: $alert-color;
    font-weight: bold;
    font-family: $title-font;

    &:hover {
      background: $section-3-color;
      color: $text-color;
    }
  }
}
