.header {
  padding: 1rem 2rem;
  height: 6rem;
  width: 100%;
  background: $section-color;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 10;

  &.menu {
    .header__nav-button {
      div:nth-child(1) {
        transform: translate(0, 1rem) rotate(-45deg);
      }
      div:nth-child(2) {
        transform: rotate(45deg);
      }
      div:nth-child(3) {
        transform: translate(0, -1.2rem);
        opacity: 0;
      }
    }

    .header__nav-bar {
      left: 0;
    }
  }

  &__back {
    background: $background-color;
    border: none;
    color: $alert-color;
    position: absolute;
    left: 2rem;
    height: 4rem;
    width: 4rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &__title {
    position: absolute;
    right: calc(50vw - 58.2px);
    a {
      img {
        height: 4rem;
        width: 116.5px;
      }
    }
  }

  &__nav-button {
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    div {
      transition: all ease 500ms;
      background: $alert-color;
      width: 3.25rem;
      height: 0.5rem;
      border-radius: 0.25rem;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &__nav-bar {
    width: 100vw;
    height: calc(100vh - 6rem);
    padding: 2rem 0;
    position: absolute;
    background: $section-color;
    transition: all ease 500ms;
    left: -100vw;
    top: 6rem;
    ul {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      li {
        text-align: center;
        a {
          font-size: 1.6rem;
          display: inline-block;
          font-weight: bold;
          padding: 0.5rem 2rem;
        }
        button {
          font-size: 1.6rem;
          padding: 0.5rem 2rem;
          display: inline-block;
          font-weight: bold;
          background: none;
          border: none;
          color: $danger-color;
        }
      }
    }

    .User-menu {
      display: block;
      text-align: center;
      display: grid;
      gap: 1rem;

      &__icon {
        i {
          color: $alert-color;
          font-size: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
        }
      }

      ul {
        display: grid;
        gap: 1rem;
        font-weight: bold;
        p {
          font-size: 1.6rem;
        }
      }
    }

    @media screen and (min-width: 768px) {
      width: initial;
      height: initial;
      position: static;
      padding: 0;
      ul {
        flex-direction: initial;
        align-items: center;
        li {
          a {
            padding: 1rem 1rem;
          }
          button {
            padding: 1rem 1rem;
          }
        }
      }

      .User-menu {
        display: block;
        position: relative;

        &__icon {
          i {
            color: $alert-color;
            font-size: 3rem;
            height: 6rem;
            width: 6rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        ul {
          position: absolute;
          width: 200px;
          right: -2rem;
          display: grid;
          padding: 2rem;
          gap: 1rem;
          font-weight: bold;
          background: $section-color;
          display: none;
          border-bottom-left-radius: 0.5rem;
          p {
            font-size: 1.6rem;
          }
        }

        &:hover ul {
          display: grid;
        }
      }
    }
  }
}
