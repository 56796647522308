.Projects {
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__bar {
    display: flex;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    // background: $section-2-color;
    // border: 2px solid $section-color;
    border-radius: 0.5rem;
    // padding: 1rem;
    gap: 1rem;

    &--search {
      display: flex;
      width: 100%;
      gap: 1rem;

      label {
        i {
          display: flex;
          padding: 1rem;
          font-size: 2rem;
          background: $section-color;
          border-radius: 50%;
          color: $alert-color;
          font-weight: bold;
        }
      }
    }

    &--new {
      background: none;
      border: none;
      i {
        display: flex;
        padding: 1rem;
        font-size: 2rem;
        background: $section-color;
        border-radius: 50%;
        color: $alert-color;
        font-weight: bold;
      }
    }
  }

  &__list {
    h2 {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        background: none;
        border: none;
        i {
          display: flex;
          padding: 1rem;
          font-size: 2rem;
          background: $section-color;
          border-radius: 50%;
          color: $alert-color;
          font-weight: bold;
        }
      }
    }
    &--container {
      display: grid;
      gap: 1rem;

      @media screen and (min-width: 620px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (min-width: 920px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (min-width: 1220px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
