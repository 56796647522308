.modal-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  min-height: 100vh;
  width: 100vw;
  background: rgba($color: #000000, $alpha: 0.75);
  display: flex;
  padding: 2rem;
  justify-content: center;
  align-items: center;
}
