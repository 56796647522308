.image-sheet {
  background: white;
  height: 297px;
  width: 210px;
  display: flex;
  flex-direction: column;
  // padding: 13.55px;
  // justify-content: center;
  justify-content: space-evenly;
  align-items: center;

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;

      &.Width {
        width: 182.9px;
        // width: 100%;
      }
      &.Height {
        height: 182.9px;
      }
    }
  }

  &__buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    button {
      margin: auto;
      display: none;
      background: rgba($section-3-color, 0.8);
      border: none;
      color: $background-color;
      border-radius: 50%;
      font-size: 2.5rem;
      i {
        display: block;
        padding: 0.75rem;
      }
    }
    &:active {
      button {
        display: block;
      }
    }
    &:hover {
      button {
        display: block;
      }
    }
  }
}
