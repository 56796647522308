.sign-up {
  padding: 4rem 2rem;
  display: flex;
  align-items: center;

  &__container {
    background: $section-color;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem 2rem 4rem;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h2 {
      font-size: 3rem;
      text-align: center;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    button {
      display: block;
      width: 100%;
      background: $section-3-color;
      font-weight: bold;
      height: 4rem;
      padding: 1rem 1rem;
      border: none;
      border-radius: 0.5rem;
    }

    p {
      font-size: 1.4rem;
    }

    .green-text {
      color: green;
    }
    .red-text {
      color: $danger-color;
    }
  }
  &__links {
    text-align: right;
  }
}
