.input-file {
  width: 4.5rem;
  label {
    background: $section-color;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    font-size: 2rem;
    &:active {
      background: $section-3-color;
    }
    @media screen and (min-width: 768px) {
      &:hover {
        background: $section-3-color;
      }
    }
  }
  input {
    display: none;
  }
}
