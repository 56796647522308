.sign-in {
  padding: 6rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    background: $section-color;
    width: 100%;
    max-width: 400px;
    padding: 2rem 2rem 4rem;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h2 {
      font-size: 3rem;
      text-align: center;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    button {
      display: block;
      width: 100%;
      background: $section-3-color;
      font-weight: bold;
      height: 4rem;
      padding: 1rem 1rem;
      border: none;
      border-radius: 0.5rem;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;

    button {
      background: none;
      border: none;
      font-weight: normal;
    }
  }
}
