@keyframes spining {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  height: calc(100vh - 6rem);
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 4rem;
    animation: spining infinite 1s linear;
  }
}
