.modal-setting {
  display: grid;
  gap: 2rem;
  &__title {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }

  &__input {
    display: grid;
    gap: 1rem;

    &--watermark {
      p {
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      label {
        font-size: 2.5rem;
        width: 4.5rem;
        height: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $text-color;
        padding: 1rem;
        border-radius: 0.5rem;
      }
      input {
        display: none;
      }
    }
  }

  &__reset {
    h3 {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
    button {
      background: $danger-color;
      border: none;
      padding: 0.5rem 2.5rem;
      border-radius: 0.5rem;
      font-size: 1.6rem;
    }
  }

  &__close {
    text-align: center;
    button {
      font-size: 1.6rem;
      padding: 0.5rem 2.5rem;
      border-radius: 0.5rem;
    }
  }
}

// width: 100%;
// display: grid;
// gap: 1rem;

// h2 {
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 0.5rem;
// }

// &__watermark {
//   h3 {
//     font-size: 1.4rem;
//     font-weight: bold;
//     color: inherit;
//   }

//   &--input {
//     padding-top: 1rem;
//     label {
//       font-weight: bold;
//       font-size: 2.5rem;
//       width: 80px;
//       height: 80px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       border: 2px solid $text-color;
//       border-radius: 0.5rem;
//     }
//     input {
//       display: none;
//     }
//   }

//   &--preview {
//     display: flex;
//     align-items: center;
//     gap: 2rem;
//     padding-top: 1rem;

//     figure {
//       padding: 0;
//       img {
//         width: 80px;
//         height: 80px;
//       }
//     }
//     button {
//       background: $danger-color;
//       border: none;
//       padding: 0.75rem 2rem;
//       border-radius: 0.25rem;
//     }
//   }
// }

// &__reset {
//   padding-top: 2rem;
//   text-align: center;
//   button {
//     background: $danger-color;
//     border: none;
//     padding: 0.75rem 2rem;
//     border-radius: 0.25rem;
//   }
// }
