@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?1bs00t");
  src: url("fonts/icomoon.eot?1bs00t#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?1bs00t") format("truetype"),
    url("fonts/icomoon.woff?1bs00t") format("woff"),
    url("fonts/icomoon.svg?1bs00t#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pictures:before {
  content: "\e900";
}
.icon-upload:before {
  content: "\e902";
}
.icon-download:before {
  content: "\e903";
}
.icon-lock-2:before {
  content: "\e904";
}
.icon-unlocked-2:before {
  content: "\e905";
}
.icon-refresh-2:before {
  content: "\e901";
}
.icon-image:before {
  content: "\e90d";
}
.icon-images:before {
  content: "\e90e";
}
.icon-user:before {
  content: "\e971";
}
.icon-load:before {
  content: "\e97a";
}
.icon-refresh:before {
  content: "\e984";
}
.icon-search:before {
  content: "\e986";
}
.icon-enlarge:before {
  content: "\e98b";
}
.icon-shrink:before {
  content: "\e98c";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-cog:before {
  content: "\e994";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-github:before {
  content: "\eab0";
}
.icon-linkedin:before {
  content: "\eac9";
}
