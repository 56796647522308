.images-pdf {
  &__header {
    background: $section-2-color;
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 1.8rem;
      }
    }
    hr {
      border-color: $text-color;
      border-bottom: none;
    }
    &--buttons {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      button {
        background: $section-color;
        height: 4.5rem;
        width: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: none;
        font-size: 2rem;
        &:active {
          background: $section-3-color;
        }
        @media screen and (min-width: 768px) {
          &:hover {
            background: $section-3-color;
          }
        }
      }
    }
    @media screen and (min-width: 768px) {
      flex-direction: row;
      padding: 1rem;
      justify-content: space-between;

      hr {
        border-right: none;
      }
    }
  }

  &__preview {
    padding: 4rem 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }

  &__empty {
    padding: 10rem 2rem;
    &--card {
      max-width: 358px;
      border: 3px solid $section-3-color;
      border-radius: 1rem;
      padding: 6rem 2rem;
      margin: 0 auto;
      background: $section-2-color;
      box-shadow: 0px 0px 15px rgba($section-3-color, 0.25);

      h3 {
        font-size: 2.4rem;
        text-align: center;
        margin-bottom: 2rem;
      }
      .input-file-empty {
        label {
          background: $section-3-color;
          margin: 0 auto;
          width: 163px;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 1rem 2rem;
          border-radius: 1rem;
          font-size: 1.6rem;
          transition: 250ms;
          i {
            font-size: 2rem;
          }
          &:active {
            transform: scale(0.9, 0.9);
          }
          @media screen and (min-width: 768px) {
            &:hover {
              transform: scale(0.9, 0.9);
            }
            &:active {
              transform: scale(0.85, 0.85);
            }
          }
        }
        input {
          display: none;
        }
      }
    }
    @media screen and (min-width: 768px) {
      height: calc(100vh - 18.4rem);
      padding: 0 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
